import { createInstance, dateReviver } from '@/api';
import { EntityModelClient, OrderDirection, SearchEntityModelDto } from '@/api/portal-api/clients';
import { useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { i18n } from '@/i18n';

export interface ModelListFilter {
    id?: number | null;
    code?: string;
    label?: string;
    description?: string;
    contextId?: string;
    entityId?: string;
    hasHistory?: boolean | undefined;
    order?: string;
    orderDirection?: OrderDirection;
    pageNumber?: number;
    pageSize?: number;
}

export interface ModelListStoreState {
    filter: ModelListFilter;
    loading: boolean;
    items?: SearchEntityModelDto[];
    totalCount?: number;
    contextTypes: string[];
    entityTypes: string[];
}

export const client = new EntityModelClient('', createInstance(), dateReviver);

export const useModelListStore = defineStore('model-list-store', {
    state: (): ModelListStoreState => ({
        filter: {
            orderDirection: OrderDirection.Ascending,
            pageNumber: 1,
            pageSize: 25,
        },
        totalCount: 0,
        loading: false,
        items: [],
        contextTypes: ['Delivery', 'Operation', 'Source', 'Product'],
        entityTypes: [
            'Point',
            'LineString',
            'Polygon',
            'GeometryCollection',
            'MultiPoint',
            'MultiLineString',
            'MultiPolygon',
            'CodeTable',
            'Table',
        ],
    }),
    getters: {
        metaData(): {
            totalRows: number;
            resultsPerPage: number;
            currentPage: number | undefined;
            serverside: boolean;
        } {
            return {
                totalRows: this.totalCount,
                resultsPerPage: this.filter.pageSize,
                currentPage: this.filter.pageNumber,
                serverside: true,
            };
        },
    },
    actions: {
        init(filter: ModelListFilter) {
            this.filter = { ...this.filter, ...filter };
            this.items = [];
        },
        clearFilter() {
            this.filter = {
                orderDirection: OrderDirection.Ascending,
                pageNumber: 1,
                pageSize: 25,
            };

            useAlertStore().clearAlert();

            this.items = [];
        },
        async getModels() {
            this.loading = true;
            useAlertStore().clearAlert();

            await client
                .search(
                    this.filter.id,
                    this.filter.code,
                    this.filter.label,
                    this.filter.description,
                    this.filter.contextId,
                    this.filter.entityId,
                    this.filter.hasHistory,
                    this.filter.order,
                    this.filter.orderDirection,
                    this.filter.pageNumber,
                    this.filter.pageSize,
                )
                .then((response) => {
                    this.items = response.items;
                    this.filter.pageNumber = response.page;
                    this.filter.pageSize = response.pageSize;
                    this.totalCount = response.totalCount;
                })
                .catch((error) => {
                    useAlertStore().setError(
                        i18n.global.t('model.list.error.title'),
                        i18n.global.t('model.list.error.content'),
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        updatePage(pageNumber: number) {
            this.filter.pageNumber = pageNumber;
        },
        updateSorting(column: string, order: string) {
            this.filter.order = column;
            this.filter.orderDirection = order === 'Asc' ? OrderDirection.Ascending : OrderDirection.Descending;
        },
    },
});
