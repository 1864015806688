import { createInstance, dateReviver } from '@/api';
import {
    EntityModelClient,
    EntityModelDetailDto,
    OrderDirection,
    SearchEntityModelDto,
} from '@/api/portal-api/clients';
import { i18n } from '@/i18n';
import { useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { useEntityAttributeStore } from '../entity-attribute/entity-attribute.store';
export interface ModelDetailStoreState {
    detail?: EntityModelDetailDto;
    loading: boolean;
}

export const client = new EntityModelClient('', createInstance(), dateReviver);

export const useModelDetailStore = defineStore('model-detail-store', {
    state: (): ModelDetailStoreState => ({
        detail: null,
        loading: false,
    }),
    getters: {},
    actions: {
        async getModel(id: number) {
            this.loading = true;

            await client
                .getDetail(id)
                .then((response) => {
                    this.detail = response;
                })
                .catch(() => {
                    useAlertStore().setError(
                        i18n.global.t('model.detail.error.title'),
                        i18n.global.t('model.detail.error.content'),
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        clear(id: number) {
            this.detail = null;
            useEntityAttributeStore().clear(id);
        },
    },
});
