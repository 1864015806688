<template>
  <vl-region>
    <vl-layout>
      <vl-grid>
        <vl-column>
          <vl-title tag-name="h1">{{ $t('delivery.create.title') }}</vl-title>
        </vl-column>
        <vl-column width="7" width-m="9" with-s="12">
          <vl-alert
            v-if="data.alert.visible"
            v-scroll="data.alert.scrollTo"
            class="vl-u-spacer--small"
            :icon="data.alert.icon"
            :mod-error="data.alert.isError"
            :mod-success="data.alert.isSuccess"
            closable
            :close-text="$t('delivery.detail.alert.closeText')"
            :title="data.alert.title"
            :content="data.alert.content"
            @close="closeAlert"
          />
        </vl-column>
        <vl-column width="7" width-m="9" with-s="12">
          <form @submit.prevent="onSubmit">
            <vl-grid mod-stacked>
              <vl-column>
                <vl-grid>
                  <vl-form-column>
                    <vl-title tag-name="h2">
                      {{ $t('delivery.create.fileDetails.title') }}
                    </vl-title>
                  </vl-form-column>
                  <vl-form-column v-vl-spacer:bottom.small>
                    <vl-form-message-label for="definition">{{
                      $t('delivery.create.definition.label')
                    }}</vl-form-message-label>
                    <Field v-slot="{ handleChange, value, errors }" name="definition">
                      <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
                      <vl-select
                        id="definition"
                        :mod-error="!!errors.length"
                        name="definition"
                        :model-value="value"
                        :placeholder-text="$t('vl-select.placeholder')"
                        mod-block
                        @input="handleChange"
                      >
                        <option v-for="definition in definitions" :key="definition.id" :value="definition.id">
                          {{ definition.label }}
                        </option>
                      </vl-select>
                    </Field>
                  </vl-form-column>
                  <vl-form-column v-vl-spacer:bottom.medium>
                    <Field v-slot="{ handleChange, value }" name="isFirstTime">
                      <vl-checkbox
                        id="isFirstTime"
                        name="isFirstTime"
                        :value="value"
                        @input="(evt) => onCheckboxChecked(evt, handleChange)"
                        >{{ $t('delivery.create.isFirstTime.description') }}&nbsp;
                        <strong> {{ $t('delivery.create.isFirstTime.label') }} </strong></vl-checkbox
                      >
                    </Field>
                  </vl-form-column>
                </vl-grid>
              </vl-column>
              <vl-column>
                <vl-grid>
                  <vl-form-column>
                    <vl-title tag-name="h2">{{ $t('delivery.create.fileUpload.label') }}</vl-title>
                  </vl-form-column>
                  <vl-form-column v-show="!loading" v-vl-spacer:bottom.medium>
                    <vl-form-message-annotation v-vl-spacer:bottom.small>
                      <vl-typography>
                        <p>
                          {{ $t('delivery.create.fileUpload.help.title') }}
                        </p>

                        <ul>
                          <li>{{ $t('delivery.create.fileUpload.help.zipfile') }}</li>
                          <li>{{ $t('delivery.create.fileUpload.help.maxlength') }}</li>
                          <li>{{ $t('delivery.create.fileUpload.help.filetypes') }}</li>
                        </ul>
                      </vl-typography>
                    </vl-form-message-annotation>
                    <Field v-slot="{ handleChange, value, errors }" name="file">
                      <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
                      <vl-upload
                        id="data-delivery-upload"
                        name="data-delivery-upload"
                        :upload-label="$t('delivery.create.fileUpload.label')"
                        allowed-file-types=".zip,.7z"
                        :max-filesize="750"
                        upload-drag-text="Sleep de bijlage naar hier om ze toe te voegen"
                        upload-response-error="Er is iets misgelopen tijdens de upload"
                        upload-invalid-file-type="Ongeldig bestandstype"
                        upload-max-files-exceeded="Het opgeladen bestand is te groot"
                        upload-files-hidden-close-label=""
                        upload-max-file-size="Het bestand van maximaal "
                        url="/api/v1/deliveryrun"
                        :mod-error="!!errors.length"
                        :max-files="1"
                        :options="options"
                        :value="value"
                        @upload-file-added="handleChange"
                        @upload-removed-file="handleChange"
                      />
                    </Field>
                  </vl-form-column>
                  <vl-form-column v-if="loading" v-vl-spacer:bottom.medium>
                    <div class="pbs-upload-progress">
                      <p class="pbs-upload-progress__file">{{ delivery.file.name }}</p>
                      <p class="pbs-upload-progress__label">
                        {{ progress }}%
                        <span class="vl-u-visually-hidden">{{ $t('create.delivery.uploaded') }}</span>
                      </p>
                      <vl-progress
                        mod-large
                        :value="progress"
                        max="100"
                        aria-valuenow="23"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        :aria-valuetext="$t('create.delivery.uploaded')"
                      />
                    </div>
                  </vl-form-column>
                </vl-grid>
              </vl-column>
              <vl-column>
                <vl-grid>
                  <vl-form-column v-vl-spacer:bottom.small>
                    <vl-title tag-name="h2">{{ $t('delivery.create.deliveryDetails.title') }}</vl-title>
                    <vl-annotation>{{ $t('delivery.create.deliveryDetails.help') }}</vl-annotation>
                  </vl-form-column>
                  <vl-form-column v-vl-spacer:bottom.medium>
                    <vl-form-message-label for="versionDate">
                      {{ $t('delivery.create.versionDate.label') }}
                      <vl-form-message-annotation style="">
                        {{ $t('delivery.create.versionDate.help') }}
                      </vl-form-message-annotation>
                    </vl-form-message-label>
                    <vl-grid>
                      <vl-column width="6">
                        <Field v-slot="{ handleChange, errors, value }" name="versionDate">
                          <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
                          <!-- https://bitbucket.org/vlaamseoverheid/vl-build/src/dev/package/vl-ui-datepicker/src/vue/vl-datepicker.js -->
                          <pbs-datepicker
                            id="versionDate"
                            name="versionDate"
                            :mod-error="!!errors.length"
                            :value="value"
                            :max-date="currentDate"
                            @input="onDatepickerInput(handleChange)($event)"
                          >
                          </pbs-datepicker>
                        </Field>
                      </vl-column>
                    </vl-grid>
                  </vl-form-column>
                  <vl-form-column v-vl-spacer:bottom.medium>
                    <vl-form-message-label
                      >{{ $t('delivery.create.temporalLimitation.label') }}
                      <vl-form-message-annotation>{{
                        $t('delivery.create.temporalLimitation.help')
                      }}</vl-form-message-annotation>
                    </vl-form-message-label>
                    <vl-grid>
                      <vl-form-column width="4" widht-m="6" width-s="12">
                        <vl-form-message-label for="temporalLimitationFrom">
                          {{ $t('delivery.create.temporalLimitation.from.label') }}
                        </vl-form-message-label>
                        <Field v-slot="{ handleChange, errors, value }" name="temporalLimitationFrom">
                          <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
                          <pbs-datepicker
                            name="temporalLimitationFrom"
                            :value="value"
                            :mod-error="!!errors.length"
                            @input="onDatepickerInput(handleChange)($event)"
                          ></pbs-datepicker>
                        </Field>
                      </vl-form-column>
                      <vl-form-column width="4" widht-m="6" width-s="12">
                        <vl-form-message-label for="temporalLimitationTo">
                          {{ $t('delivery.create.temporalLimitation.to.label') }}
                          <vl-form-message-annotation tag-name="span"
                            >({{ $t('general.optional') }})</vl-form-message-annotation
                          >
                        </vl-form-message-label>
                        <Field v-slot="{ handleChange, errors, value }" name="temporalLimitationTo">
                          <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
                          <pbs-datepicker
                            name="temporalLimitationTo"
                            :value="value"
                            :min-date="values.temporalLimitationFrom"
                            :mod-error="!!errors.length"
                            @input="onDatepickerInput(handleChange)($event)"
                          ></pbs-datepicker>
                        </Field>
                      </vl-form-column>
                    </vl-grid>
                  </vl-form-column>
                  <vl-form-column v-vl-spacer:bottom.medium>
                    <vl-form-message-label for="VersionDescription"
                      >{{ $t('delivery.create.description.label') }}
                      <vl-form-message-annotation>{{
                        $t('delivery.create.description.help')
                      }}</vl-form-message-annotation>
                    </vl-form-message-label>
                    <Field v-slot="{ handleChange, value, errors }" name="versionDescription">
                      <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
                      <vl-textarea
                        id="VersionDescription"
                        name="VersionDescription"
                        :placeholder="$t('delivery.create.description.placeholder')"
                        rows="6"
                        :modelValue="value"
                        :mod-error="!!errors.length"
                        mod-block
                        @input="handleChange"
                      ></vl-textarea>
                    </Field>
                  </vl-form-column>
                </vl-grid>
              </vl-column>
              <vl-column>
                <div class="vl-u-spacer"></div>
                <vl-action-group>
                  <vl-button type="submit" :mod-loading="loading">{{ $t('delivery.create.confirm.label') }}</vl-button>
                  <vl-button type="button" mod-secondary @click="cancel">{{
                    $t('delivery.create.cancel.label')
                  }}</vl-button>
                </vl-action-group>
              </vl-column>
            </vl-grid>
          </form>
        </vl-column>
      </vl-grid>
    </vl-layout>
  </vl-region>
</template>

<script lang="ts" setup>
import { reactive, onMounted, computed } from 'vue';
import { DeliveryCreateServiceInstance, UploadError, CreateDelivery } from './DeliveryCreate.service';
import { useRouter } from 'vue-router';
import { format, getDate } from 'date-fns';
import { DropzoneFile, DropzoneOptions } from 'dropzone';
import type Dropzone from 'dropzone';
import { Form, Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import { parseFormattedDateString, yupTransformDate } from '@/helpers';
import { useI18n } from 'vue-i18n';
import PbsDatepicker from '@/components/forms/PbsDatepicker.vue';

const router = useRouter();

const data = reactive({
  deliveryService: DeliveryCreateServiceInstance,
  alert: {
    visible: false,
    isError: false,
    isSuccess: false,
    scrollTo: false,
    title: '',
    content: '',
    icon: '',
  },
});

const { t } = useI18n();

const options: DropzoneOptions = {
  init: function (): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const dropzone: Dropzone = this;
    // Hack to fix 'reuploading' of a file. Uploading a new file replaces the other one.
    dropzone.on('maxfilesexceeded', function (file: DropzoneFile) {
      dropzone.removeAllFiles();
      dropzone.addFile(file);
    });
  },
};

const translations = {
  definition: {
    required: t('validations.messages.required', {
      _field_: t('delivery.create.definition.label'),
    }),
    oneOf: t('validations.messages.oneOf', {
      _field_: t('delivery.create.definition.label'),
    }),
  },
  versionDate: {
    required: t('validations.messages.required', {
      _field_: t('delivery.create.versionDate.label'),
    }),
  },
  temporalLimitationFrom: {
    required: t('validations.messages.required', {
      _field_: t('delivery.create.temporalLimitation.from.label'),
    }),
  },
  versionDescription: {
    required: t('validations.messages.required', {
      _field_: t('delivery.create.description.label'),
    }),
  },
  file: {
    required: t('validations.messages.required', {
      _field_: t('delivery.create.fileUpload.label'),
    }),
    fileType: t('validations.messages.invalidFileType'),
    fileSize: t('validations.messages.invalidFileSize'),
  },
  form: {
    uploadFailed: t('deliver.create.uploadfailed'),
  },
};

const onCheckboxChecked = (evt, handleChange) => {
  handleChange(evt.target.checked);
};

const delivery = computed(() => {
  return data.deliveryService.delivery;
});
const definitions = computed(() => data.deliveryService.definitions);
const loading = computed(() => data.deliveryService.loading);
const progress = computed(() => data.deliveryService.uploadProgress);
const currentDate = computed(() => format(new Date(), 'dd.MM.yyyy'));

const validationSchema = yup.object({
  definition: yup
    .number()
    .test('computed-oneOf', translations.definition.oneOf, (value) => {
      const definition = definitions.value.find((x) => x.id === value);
      return !!definition;
    })
    .required(translations.definition.required),
  versionDate: yup.date().transform(yupTransformDate).required(translations.versionDate.required),
  temporalLimitationFrom: yup.date().transform(yupTransformDate).required(translations.temporalLimitationFrom.required),
  temporalLimitationTo: yup.date().nullable().transform(yupTransformDate),
  versionDescription: yup.string().required(translations.versionDescription.required),
  isFirstTime: yup.boolean(),
  file: yup
    .mixed()
    .required(translations.file.required)
    .test('is-valid-type', translations.file.fileType, (value) => {
      return (
        value && ((value as File).type === 'application/x-zip-compressed' || (value as File).type === 'application/zip')
      );
    })
    .test('is-valid-size', translations.file.fileSize, (value) => value && (value as File).size <= 750 * 1000 * 1000),
});

const { resetForm, handleSubmit, values } = useForm({
  validationSchema,
});

const onDatepickerInput = (handleChange) => (value) => {
  handleChange(Array.isArray(value) ? value[0] : value);
};

onMounted(async () => {
  await DeliveryCreateServiceInstance.init();
  resetForm({ values: delivery.value });
});

const onSuccess = async (values) => {
  const payload: CreateDelivery = {
    definition: definitions.value.find((p) => p.id == values.definition),
    versionDescription: values.versionDescription,
    isFirstTime: values.isFirstTime,
    versionDate: parseFormattedDateString(values.versionDate),
    temporalLimitationFrom: parseFormattedDateString(values.temporalLimitationFrom),
    temporalLimitationTo: parseFormattedDateString(values.temporalLimitationTo),
    file: values.file,
  };
  await create(payload);
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

const create = async (delivery: CreateDelivery) => {
  try {
    closeAlert();
    const response = await data.deliveryService.create(delivery);
    router.push({ name: 'delivery.detail', params: { id: `${response.id}` }, query: { created: 'true' } });
  } catch (e: any) {
    const { detail, title } = parseException(e);
    alertError(detail, title);
  }
};

const parseException = (e: any) => {
  let title = translations.form.uploadFailed;
  let detail = '';

  if (e.status === 400) {
    const errors = e.errors;
    if (errors && Array.isArray(errors) && errors.length > 0) {
      if (errors[0].detail) {
        detail = errors.map((err) => err.detail).join(',');
      } else {
        detail = errors.join(',');
      }
    } else if (e.message) {
      detail = e.message;
    }
  } else if (e.status === 413) {
    title = translations.file.fileSize;
  }

  return { title, detail };
};

const alertError = (message: string, title: string) => {
  data.alert = {
    visible: true,
    isError: true,
    isSuccess: false,
    scrollTo: true,
    icon: 'alert-circle',
    title: title,
    content: message,
  };
};

const closeAlert = () => {
  data.alert = {
    visible: false,
    isError: false,
    isSuccess: false,
    scrollTo: false,
    icon: '',
    title: '',
    content: '',
  };
};

const cancel = () => {
  data.deliveryService.cancel();
  // Go back in history
  router.go(-1);
};
</script>
<style lang="scss">
@import '@govflanders/vl-ui-design-system-style/scss/core/_core.scss';
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colors.scss';

.pbs-upload-progress {
  border: 1px $vl-input-border-color solid;
  border-radius: 2px;
  padding: calc(#{$vl-input-padding-sides} * 2);

  &__file {
    text-transform: uppercase;
    display: inline-flex;
    margin-top: -29px;
    margin-left: -5px;
    padding: 0 5px;
    letter-spacing: 1px;
    font-size: 12px;
    color: $vl-text-color;
    background: white;
  }

  &__label {
    text-align: center;
  }
  progress::-moz-progress-bar {
    background: $vl-yellow;
  }
  progress::-webkit-progress-value {
    background: $vl-yellow;
  }
  progress {
    color: $vl-yellow;
  }
}

.vl-upload__files__close {
  display: none;
}
</style>
