<template>
  <vl-layout>
    <vl-grid v-vl-spacer:bottom.small>
      <template v-for="(domain, i) in getDomainConfigurations()" :key="i">
        <vl-column v-if="hasRolesAsync(domain.roles)" width="3">
          <vl-doormat
            v-vl-spacer:bottom.small
            :title="domain.title"
            style="cursor: pointer"
            @click="goTo(domain.route)"
          >
            {{ domain.content }}
          </vl-doormat>
        </vl-column>
      </template>
    </vl-grid>
  </vl-layout>
</template>
<script lang="ts" setup>
import { useUserStore } from '@/store/user.store';
import { getDomainConfigurations } from '@/core/security/domain.config.ts';
import { useRouter } from 'vue-router';
const { hasRolesAsync } = useUserStore();
const router = useRouter();

const goTo = (route: string) => {
  router.push(route);
};
</script>
