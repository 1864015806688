<template>
  <vl-layout>
    <template v-if="!loading">
      <vl-grid>
        <vl-column width="8" width-xs="12">
          <vl-title tag-name="h1"> {{ $t('model.detail.title') }} </vl-title>
        </vl-column>
        <!-- <vl-column width="4" width-xs="12">
          <vl-action-group mod-align-right mod-space between>
            <vl-button icon="pencil" mod-icon-before mod-secondary>{{ $t('general.edit') }}</vl-button>
            <vl-button icon="trash" mod-icon-before mod-error>{{ $t('general.delete') }}</vl-button>
          </vl-action-group>
        </vl-column> -->
      </vl-grid>
      <vl-grid v-if="detail" v-vl-spacer:bottom.medium>
        <vl-column>
          <vl-properties>
            <vl-properties-column>
              <vl-properties-list>
                <vl-properties-label>{{ $t('model.detail.id') }}</vl-properties-label>
                <vl-properties-data>{{ detail.id }}</vl-properties-data>
                <vl-properties-label>{{ $t('model.detail.code') }}</vl-properties-label>
                <vl-properties-data>{{ detail.code }}</vl-properties-data>
                <vl-properties-label>{{ $t('model.detail.label') }}</vl-properties-label>
                <vl-properties-data>{{ detail.label }}</vl-properties-data>
                <vl-properties-label>{{ $t('model.detail.description') }}</vl-properties-label>
                <vl-properties-data>{{ detail.description }}</vl-properties-data>
                <vl-properties-label>{{ $t('model.detail.contextType') }}</vl-properties-label>
                <vl-properties-data>{{ detail.contextTypeName }}</vl-properties-data>
                <vl-properties-label>{{ $t('model.detail.entityType') }}</vl-properties-label>
                <vl-properties-data>{{ detail.entityTypeName }}</vl-properties-data>
                <vl-properties-label>{{ $t('model.detail.hasHistory') }}</vl-properties-label>
                <vl-properties-data>{{ detail.hasHistory ? $t('general.yes') : $t('general.no') }}</vl-properties-data>
              </vl-properties-list>
            </vl-properties-column>
          </vl-properties>
        </vl-column>
      </vl-grid>
    </template>
    <template v-if="detail && !loading">
      <EntityAttribute :model="detail" :modelId="id" />
    </template>
    <template v-if="loading">
      <vl-region>
        <div v-vl-align:center>
          <vl-loader :message="$t('model.detail.loading')" />
        </div>
      </vl-region>
    </template>
  </vl-layout>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { onMounted } from 'vue';
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import { useModelDetailStore } from '../../store/model/model-detail.store';
import { EntityModelDetailDto } from '@/api/portal-api/clients';
import EntityAttribute from './subcomponents/EntityAttribute.vue';

const store = useModelDetailStore();

const route = useRoute();

const detail = computed((): EntityModelDetailDto => {
  return store.detail;
});

const loading = computed((): boolean => {
  return store.loading;
});

const id = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

onMounted(async () => {
  await store.getModel(id.value);
});

onBeforeRouteLeave((to, from, next) => {
  const id = parseInt(from.params.id as string, 10);
  if (id && store) {
    store.clear(id);
  }
  next();
});
</script>
<style lang="scss" scoped>
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colorsRaw.scss';
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colors.scss';

.pbs-form-group {
  background: $vl-alt-bg;
  padding: 3rem;
}
</style>
