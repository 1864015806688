import { defineStore } from 'pinia';
import { clients, Organisation } from '@/api';

export type OrganisationStoreState = {
    current: Organisation | null;
    initialized: boolean;
    organisations: Organisation[];
};

export type OrganisationStoreGetters = {
    hasOrganisation: () => boolean;
    name: () => string;
};

export type OrganisationStoreActions = {
    setOrganisation: (organisation?: Organisation | null) => void;
    fetchOrganisation: () => Promise<void>;
    setOrganisations: (organisations?: Organisation[]) => void;
    impersonate: (code: string) => Promise<void>;
    fetchOrganisations: () => Promise<void>;
    signin: () => Promise<void>;
    signout: () => Promise<void>;
    hasOrganisationAsync: () => Promise<boolean>;
};

export const useOrganisationStore = defineStore<
    string,
    OrganisationStoreState,
    OrganisationStoreGetters,
    OrganisationStoreActions
>('organisation-store', {
    state: (): OrganisationStoreState => ({
        current: null,
        initialized: false,
        organisations: [],
    }),
    getters: {
        hasOrganisation(): boolean {
            return this.current !== null && this.current !== undefined;
        },
        name(): string {
            if (!this.hasOrganisation) {
                return '';
            }
            return this.current?.name || '';
        },
    },
    actions: {
        setOrganisation(organisation: Organisation | null = null): void {
            this.initialized = true;
            this.current = organisation;
        },
        setOrganisations(organisations: Organisation[] = []): void {
            this.organisations = [...organisations];
        },
        async fetchOrganisation(): Promise<void> {
            try {
                const organisation = await clients.OrganisationClient.current();
                this.setOrganisation(organisation);
            } catch {
                this.setOrganisation();
            }
        },
        async fetchOrganisations(): Promise<void> {
            try {
                const organisations = await clients.OrganisationClient.getAll();
                this.setOrganisations(organisations);
            } catch {
                this.setOrganisations();
            }
        },
        async impersonate(code: string): Promise<void> {
            await clients.OrganisationClient.impersonate(code);
            return this.fetchOrganisation();
        },
        async signin(): Promise<void> {
            await clients.OrganisationClient.signin();
            return this.fetchOrganisation();
        },
        async signout(): Promise<void> {
            await clients.OrganisationClient.signout();
            return this.fetchOrganisation();
        },
        async hasOrganisationAsync(): Promise<boolean> {
            if (!this.initialized) {
                await this.fetchOrganisation();
            }
            return this.hasOrganisation;
        },
    },
});
