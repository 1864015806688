import Vue, { reactive } from 'vue';

import { clients, ProductRun, IProductRunClient } from '@/api';

export class ProductDetailService {
    public state: { product: ProductRun } = reactive({ product: {} });

    constructor(private productClient: IProductRunClient) {}

    get product(): ProductRun {
        return this.state.product;
    }

    async init(productId: number): Promise<void> {
        this.reset();
        await this.getData(productId);
    }

    async getData(productId: number) {
        this.state.product = await this.productClient.get(productId);
    }

    reset(): void {
        this.state.product = {};
    }
}

export const ProductDetailServiceInstance = new ProductDetailService(clients.ProductRunClient);
