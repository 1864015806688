import { RouteRecordRaw } from 'vue-router';
import ModelDetail from './model/ModelDetail.vue';
import { configuratiebeheerder } from '@/core/security/roleTypes';
import Configuration from './Configuration.vue';
import ProcessDeliveryDetail from './process-delivery/ProcessDeliveryDetail.vue';
import ProcessDeliveryCreate from './process-delivery/ProcessDeliveryCreate.vue';

export const configurationRoutes: RouteRecordRaw = {
    path: '/configuration',
    meta: {
        hasRoles: [configuratiebeheerder],
        feature: 'configuration',
    },
    children: [
        {
            path: '',
            name: 'configuration',
            component: Configuration,
            meta: {
                hasRoles: [configuratiebeheerder],
            },
        },
        {
            path: 'model/:id',
            name: 'model.detail',
            component: ModelDetail,
            meta: {
                hasRoles: [configuratiebeheerder],
            },
        },
        {
            path: 'processdelivery/:id',
            name: 'processdelivery.detail',
            component: ProcessDeliveryDetail,
            meta: {
                hasRoles: [configuratiebeheerder],
            },
        },
        {
            path: 'processdelivery/create',
            name: 'processdelivery.create',
            component: ProcessDeliveryCreate,
            meta: {
                hasRoles: [configuratiebeheerder],
            },
        },
    ],
};
