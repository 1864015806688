import { createInstance, dateReviver } from '@/api';
import { DeliverySpecificationClient, DeliverySpecificationDetailDto } from '@/api/portal-api/clients';
import { i18n } from '@/i18n';
import { useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { useDeliveryEntityStore } from '../delivery-entity/delivery-entity.store';

export interface DeliverySpecificationStoreState {
    items?: { key: number; value: DeliverySpecificationDetailDto[] }[];
    loading: boolean;
}

export const client = new DeliverySpecificationClient('', createInstance(), dateReviver);

export const useDeliverySpecificationStore = defineStore('delivery-specification-store', {
    state: (): DeliverySpecificationStoreState => ({
        items: [],
        loading: false,
    }),
    getters: {},
    actions: {
        async getByDefinition(id: number) {
            this.loading = true;

            await client
                .getByDefinition(id)
                .then((response) => {
                    this.items = [...this.items, { key: id, value: response }];
                })
                .catch(() => {
                    useAlertStore().setError(
                        i18n.global.t('deliveryspecification.detail.error.title'),
                        i18n.global.t('deliveryspecification.detail.error.content'),
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async update(id: number) {
            this.loading = true;

            this.loading = false;
        },
        clear(id: number) {
            const specification = this.items.find((x) => x.key === id)?.value;

            if (specification) {
                specification.forEach((s) => {
                    useDeliveryEntityStore().clear(s.id);
                });
            }

            this.items = this.items.filter((x) => x.key !== id);
        },
    },
});
