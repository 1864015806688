<template>
  <vl-layout>
    <vl-grid>
      <vl-column width="12" width-xs="12">
        <vl-title tag-name="h1"> {{ $t('processdelivery.create.title') }} </vl-title>
      </vl-column>
    </vl-grid>
    <form @submit="onSubmit">
      <vl-grid>
        <vl-form-column v-vl-spacer:bottom.small>
          <vl-form-message-label for="label"> {{ $t('processdelivery.create.label') }} </vl-form-message-label>
          <Field v-slot="{ handleChange, value, errors }" name="label">
            <vl-input-field
              id="label"
              name="label"
              :placeholder="$t('processdelivery.create.label')"
              :modelValue="value"
              :mod-error="!!errors.length"
              mod-block
              @input="handleChange"
            >
            </vl-input-field>
            <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
          </Field>
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.small>
          <vl-form-message-label for="description">
            {{ $t('processdelivery.create.description') }}
          </vl-form-message-label>
          <Field v-slot="{ handleChange, value, errors }" name="description">
            <vl-input-field
              id="description"
              name="description"
              :placeholder="$t('processdelivery.create.description')"
              :modelValue="value"
              :mod-error="!!errors.length"
              mod-block
              @input="handleChange"
            >
            </vl-input-field>
            <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
          </Field>
        </vl-form-column>
        <vl-action-group v-vl-spacer:bottom.small>
          <vl-button icon="save" :mod-loading="loading" mod-icon-before type="submit"
            >{{ $t('general.save') }}
          </vl-button>
        </vl-action-group>
      </vl-grid>
    </form>
  </vl-layout>
</template>
<script lang="ts" setup>
import { Field, useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { object, string } from 'yup';
import { useProcessDeliveryDetailStore } from '../../store/process-delivery/process-delivery-detail.store';
import { useRouter } from 'vue-router';
import { computed } from 'vue';

const { t } = useI18n();
const store = useProcessDeliveryDetailStore();
const router = useRouter();

const validationSchema = object({
  label: string()
    .required(t('validations.messages.required', { _field_: t('processdelivery.create.label') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('processdelivery.detail.label'), length: 256 })),
  description: string().required(
    t('validations.messages.required', { _field_: t('processdelivery.create.description') }),
  ),
});
const { handleSubmit } = useForm({
  validationSchema,
});

const loading = computed((): boolean => {
  return store.loading;
});

const onSuccess = async (values) => {
  const id = await store.create(values);

  if (id) {
    router.push({
      name: 'processdelivery.detail',
      params: {
        id,
      },
    });
  }
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

defineExpose({ onSubmit });
</script>
