<template>
  <vl-layout>
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-column width="12" width-xs="12">
        <vl-title tag-name="h1">
          {{ $t('configuration.title') }}
        </vl-title>
      </vl-column>
    </vl-grid>
    <vl-tabs :active-tab="data.activeTab" :hash-change="true" @tab-change="onTabChange">
      <vl-tab v-if="isFeatureEnabled('configurationEntityModelSearch')" :id="'model'" label="Entiteitsmodellen">
        <ModelTab v-if="data.activeTab === 0"></ModelTab>
      </vl-tab>
      <vl-tab
        v-if="isFeatureEnabled('configurationDeliveryProcesDefinitionSearch')"
        :id="'processdelivery'"
        label="Leveringsprocesdefinities"
      >
        <ProcessDeliveryTab v-if="data.activeTab === 1"></ProcessDeliveryTab>
      </vl-tab>
    </vl-tabs>
  </vl-layout>
</template>
<script lang="ts" setup>
import { FeatureKeys, isFeatureEnabled } from '@/common/config/config';
import { reactive, onBeforeMount } from 'vue';
import ModelTab from '../components/ModelTab.vue';
import ProcessDeliveryTab from '../components/ProcessDeliveryTab.vue';
import { useRoute } from 'vue-router';
import router from '@/router';

const tabs = [
  {
    index: 0,
    hash: 'model',
  },
  { index: 1, hash: 'processdelivery' },
];

const data = reactive({
  activeTab: null,
});

const route = useRoute();

onBeforeMount(() => {
  data.activeTab = tabs.find((t) => t.hash.toLowerCase() === route.hash?.replace('#', '')?.toLowerCase())?.index ?? 0;
});

const onTabChange = (tab) => {
  data.activeTab = tab.index;
  router.push({ query: route.query, hash: `#` + tab.id });
};
</script>

<style lang="scss" scoped>
:deep(.vl-tabs) {
  margin-bottom: 0;
}
</style>
