import Vue, { reactive } from 'vue';

import { clients, DeliveryRun, IDeliveryRunClient } from '@/api';
import downloadBlob from '@/helpers/download.helpers';

export class DeliveryDetailService {
    public state = reactive({ delivery: {} as DeliveryRun });

    constructor(private deliveryClient: IDeliveryRunClient) {}

    get delivery(): DeliveryRun {
        return this.state.delivery;
    }

    async cancelDelivery(): Promise<void> {
        if (!this.delivery?.id) {
            return;
        }
        await this.deliveryClient.cancel(this.delivery.id);
        await this.getData(this.delivery.id);
    }

    async init(deliveryId: number): Promise<void> {
        this.reset();
        await this.getData(deliveryId);
    }

    async getData(deliveryId: number) {
        this.state.delivery = await this.deliveryClient.get(deliveryId);
    }

    reset(): void {
        this.state.delivery = {};
    }

    async download(): Promise<void> {
        if (!this.delivery?.id) {
            return;
        }
        const file = await this.deliveryClient.getFile(this.delivery?.id);

        return new Promise((resolve, reject) => {
            try {
                downloadBlob(file.data, file.fileName, () => {
                    resolve();
                });
            } catch (e) {
                reject(e);
            }
        });
    }
}

export const DeliveryDetailServiceInstance = new DeliveryDetailService(clients.DeliveryRunClient);
