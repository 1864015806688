<template>
  <vl-layout>
    <template v-if="!loading">
      <vl-grid>
        <vl-column width="8" width-xs="12">
          <vl-title tag-name="h1"> {{ $t('processdelivery.detail.title') }} </vl-title>
        </vl-column>
        <vl-column width="4" width-xs="12">
          <vl-action-group
            v-if="detail && !editStore.isEdit(ProcessDeliveryEditType.ProcessDelivery, detail.id)"
            mod-align-right
            mod-space
            between
          >
            <vl-button
              icon="pencil"
              mod-icon-before
              mod-secondary
              :mod-disabled="editStore.editing"
              @click="editStore.openEdit(ProcessDeliveryEditType.ProcessDelivery, detail.id)"
            >
              {{ $t('general.edit') }}
            </vl-button>
            <!-- <vl-button v-if="!hasChildren" icon="trash" mod-icon-before mod-error>{{ $t('general.delete') }}</vl-button> -->
          </vl-action-group>
          <vl-action-group v-else mod-align-right mod-space between>
            <vl-button icon="save" :mod-loading="loading" mod-icon-before @click="save">{{
              $t('general.save')
            }}</vl-button>
            <vl-button
              icon="cross"
              :mod-loading="loading"
              mod-icon-before
              mod-secondary
              mod-error
              @click="editStore.closeEdit"
            >
              {{ $t('general.cancel') }}
            </vl-button>
          </vl-action-group>
        </vl-column>
      </vl-grid>
      <vl-grid
        v-if="detail"
        v-vl-spacer:bottom.medium
        :class="{ edit: editStore.isEdit(ProcessDeliveryEditType.ProcessDelivery, detail.id) }"
      >
        <vl-column>
          <ProcessDeliveryRead v-if="!editStore.isEdit(ProcessDeliveryEditType.ProcessDelivery, detail.id)" />
          <ProcessDeliveryEdit v-else ref="editForm" />
        </vl-column>
      </vl-grid>
      <!-- <template v-if="detail && !loading">
        <DeliverySpecification />
        <OperationSpecification />
        <DeliveryTransformation />
      </template> -->
    </template>
    <template v-if="loading">
      <vl-region>
        <div v-vl-align:center>
          <vl-loader :message="$t('processdelivery.detail.loading')" />
        </div>
      </vl-region>
    </template>
  </vl-layout>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue';
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import { useProcessDeliveryDetailStore } from '../../store/process-delivery/process-delivery-detail.store';
import { DeliveryProcessDefinitionDetailDto } from '@/api/portal-api/clients';
import DeliverySpecification from './subcomponents/delivery-specification/DeliverySpecification.vue';
import ProcessDeliveryRead from './subcomponents/process-delivery/ProcessDeliveryRead.vue';
import OperationSpecification from './subcomponents/operation-specification/OperationSpecification.vue';
import DeliveryTransformation from './subcomponents/delivery-transformation/DeliveryTransformation.vue';
import ProcessDeliveryEdit from './subcomponents/process-delivery/ProcessDeliveryEdit.vue';
import { useDeliverySpecificationStore } from '../../store/delivery-specification/delivery-specification.store';
import { useOperationEntityStore } from '../../store/operation-entity/operation-entity.store';
import { useDeliveryTransformationStore } from '../../store/delivery-transformation/delivery-transformation.store';
import {
  ProcessDeliveryEditType,
  useProcessDeliveryEditStore,
} from '../../store/process-delivery/process-delivery-edit.store';
import { useAlertStore } from '@/modules/core/store/alert.store';

const store = useProcessDeliveryDetailStore();
const editStore = useProcessDeliveryEditStore();

const route = useRoute();

const editForm = ref();

const detail = computed((): DeliveryProcessDefinitionDetailDto => {
  return store.detail;
});

const loading = computed((): boolean => {
  return store.loading;
});

const hasChildren = computed((): boolean => {
  return (
    useDeliverySpecificationStore().items?.length > 0 ||
    useOperationEntityStore().items?.length > 0 ||
    useDeliveryTransformationStore().items?.length > 0
  );
});

const id = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

onMounted(() => {
  useAlertStore().clearAlert();
  store.get(id.value);
});

const save = () => {
  editForm.value.onSubmit();
};

onBeforeRouteLeave((to, from, next) => {
  const id = parseInt(from.params.id as string, 10);
  if (id && store) {
    store.clear(id);
    editStore.closeEdit();
  }
  next();
});
</script>
