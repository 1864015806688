import { createInstance, dateReviver } from '@/api';
import { DeliveryTransformationClient, DeliveryTransformationDetailDto } from '@/api/portal-api/clients';
import { i18n } from '@/i18n';
import { useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';

export interface DeliveryTransformationStoreState {
    items?: { key: number; value: DeliveryTransformationDetailDto[] }[];
    loading: boolean;
}

export const client = new DeliveryTransformationClient('', createInstance(), dateReviver);

export const useDeliveryTransformationStore = defineStore('delivery-transformation-store', {
    state: (): DeliveryTransformationStoreState => ({
        items: [],
        loading: false,
    }),
    getters: {},
    actions: {
        async getByDefinition(id: number) {
            this.loading = true;

            await client
                .getByDefinition(id)
                .then((response) => {
                    this.items = [...this.items, { key: id, value: response }];
                })
                .catch(() => {
                    useAlertStore().setError(
                        i18n.global.t('deliverytransformation.detail.error.title'),
                        i18n.global.t('deliverytransformation.detail.error.content'),
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        clear(id: number) {
            this.items = this.items.filter((x) => x.key !== id);
        },
    },
});
