<template>
  <div>
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-column width="10" width-xs="12">
        <vl-title tag-name="h2"> {{ $t('entityAttribute.detail.title') }} </vl-title>
      </vl-column>
      <!-- <vl-column width="2" width-xs="12">
        <vl-action-group mod-align-right>
          <vl-button icon="plus" mod-icon-before>{{ $t('general.add') }}</vl-button>
        </vl-action-group>
      </vl-column> -->
    </vl-grid>
    <vl-grid v-if="!loading && items && items.length">
      <vl-accordion-list>
        <vl-accordion-list-item v-for="(entityAttribute, i) in items" :id="i" :key="i">
          <vl-accordion :id="i.toString()" :title="getLabel(entityAttribute)">
            <vl-grid>
              <vl-column width="8">
                <vl-properties-list v-vl-spacer:bottom.small>
                  <vl-properties-label>{{ $t('entityAttribute.detail.id') }}</vl-properties-label>
                  <vl-properties-data>{{ entityAttribute.id }}</vl-properties-data>
                  <vl-properties-label>{{ $t('entityAttribute.detail.code') }}</vl-properties-label>
                  <vl-properties-data>{{ entityAttribute.code }}</vl-properties-data>
                  <vl-properties-label>{{ $t('entityAttribute.detail.label') }}</vl-properties-label>
                  <vl-properties-data>{{ entityAttribute.label }}</vl-properties-data>
                  <vl-properties-label>{{ $t('entityAttribute.detail.description') }}</vl-properties-label>
                  <vl-properties-data>{{ entityAttribute.description }}</vl-properties-data>
                  <vl-properties-label>{{ $t('entityAttribute.detail.order') }}</vl-properties-label>
                  <vl-properties-data>{{ entityAttribute.order }}</vl-properties-data>
                  <vl-properties-label>{{ $t('entityAttribute.detail.dataType') }}</vl-properties-label>
                  <vl-properties-data>{{ entityAttribute.dataType }}</vl-properties-data>
                  <vl-properties-label>{{ $t('entityAttribute.detail.length') }}</vl-properties-label>
                  <vl-properties-data>{{ entityAttribute.length }}</vl-properties-data>
                  <vl-properties-label>{{ $t('entityAttribute.detail.decimalCount') }}</vl-properties-label>
                  <vl-properties-data>{{ entityAttribute.decimalCount || '-' }}</vl-properties-data>
                  <vl-properties-label>{{ $t('entityAttribute.detail.isIdentifying') }}</vl-properties-label>
                  <vl-properties-data>{{
                    entityAttribute.isIdentifying ? $t('general.yes') : $t('general.no')
                  }}</vl-properties-data>
                  <vl-properties-label>{{ $t('entityAttribute.detail.isMandatory') }}</vl-properties-label>
                  <vl-properties-data>{{
                    entityAttribute.isMandatory ? $t('general.yes') : $t('general.no')
                  }}</vl-properties-data>
                  <vl-properties-label>{{ $t('entityAttribute.detail.isCalculated') }}</vl-properties-label>
                  <vl-properties-data>{{
                    entityAttribute.isCalculated ? $t('general.yes') : $t('general.no')
                  }}</vl-properties-data>
                </vl-properties-list>
              </vl-column>
              <!-- <vl-column width="4">
                <vl-action-group mod-align-right mod-space between>
                  <vl-button icon="pencil" mod-narrow mod-icon-before mod-secondary>{{ $t('general.edit') }}</vl-button>
                  <vl-button icon="trash" mod-narrow mod-icon-before mod-error>{{ $t('general.delete') }}</vl-button>
                </vl-action-group>
              </vl-column> -->
            </vl-grid></vl-accordion
          >
        </vl-accordion-list-item>
      </vl-accordion-list>
    </vl-grid>
  </div>
</template>
<script lang="ts" setup>
import { EntityAttributeDetailDto, EntityModelDetailDto } from '@/api/portal-api/clients';
import { useEntityAttributeStore } from '@/modules/configuration/store/entity-attribute/entity-attribute.store';
import { computed } from 'vue';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const store = useEntityAttributeStore();
const route = useRoute();
const { t } = useI18n();

const loading = computed((): boolean => {
  return store.loading;
});

const items = computed((): EntityAttributeDetailDto[] => {
  return store.items?.find((x) => x.key === id.value)?.value ?? [];
});

const id = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

onMounted(async () => {
  await store.getByModel(id.value);
});

const getLabel = (item: EntityAttributeDetailDto): string => {
  const prefix = t('entityAttribute.detail.attribute');
  return `${prefix} ${item.order}: ${item.code}`;
};
</script>
<style lang="scss" scoped></style>
