<template>
  <form>
    <vl-properties-list>
      <vl-properties-label>{{ $t('processdelivery.detail.id') }}</vl-properties-label>
      <vl-properties-data>{{ detail.id }}</vl-properties-data>
    </vl-properties-list>

    <vl-form-column v-vl-spacer:bottom.small>
      <vl-form-message-label for="label"> {{ $t('processdelivery.detail.label') }} </vl-form-message-label>
      <Field v-slot="{ handleChange, value, errors }" name="label">
        <vl-input-field
          id="label"
          name="label"
          :placeholder="$t('processdelivery.detail.label')"
          rows="6"
          :modelValue="value"
          :mod-error="!!errors.length"
          mod-block
          @input="handleChange"
        ></vl-input-field>
        <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
      </Field>
    </vl-form-column>

    <vl-form-column v-vl-spacer:bottom.small>
      <vl-form-message-label for="description"> {{ $t('processdelivery.detail.description') }} </vl-form-message-label>
      <Field v-slot="{ handleChange, value, errors }" name="description">
        <vl-input-field
          id="description"
          name="description"
          :placeholder="$t('processdelivery.detail.description')"
          rows="6"
          :modelValue="value"
          :mod-error="!!errors.length"
          mod-block
          @input="handleChange"
        ></vl-input-field>
        <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
      </Field>
    </vl-form-column>
  </form>
</template>

<script lang="ts" setup>
import { DeliveryProcessDefinitionDetailDto } from '@/api/portal-api/clients';
import { useProcessDeliveryDetailStore } from '@/modules/configuration/store/process-delivery/process-delivery-detail.store';
import { Field, useForm } from 'vee-validate';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { object, string } from 'yup';

const store = useProcessDeliveryDetailStore();
const { t } = useI18n();

const validationSchema = object({
  label: string()
    .required(t('validations.messages.required', { _field_: t('processdelivery.detail.label') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('processdelivery.detail.label'), length: 256 })),
  description: string().required(
    t('validations.messages.required', { _field_: t('processdelivery.detail.description') }),
  ),
});
const { resetForm, handleSubmit } = useForm({
  validationSchema,
});

onMounted(() => {
  resetForm({ values: detail.value });
});

const detail = computed((): DeliveryProcessDefinitionDetailDto => {
  return store.detail;
});

const onSuccess = async (values) => {
  await store.update(detail.value.id, values);
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

defineExpose({ onSubmit });
</script>
