<template>
  <div class="organisation">
    <vl-region mod-stacked-large>
      <vl-layout>
        <vl-grid mod-stacked>
          <vl-column>
            <vl-grid mod-center>
              <vl-column width="4" width-m="9" width-s="12">
                <vl-title tag-name="h1">{{ $t('organisation.title') }}</vl-title>
              </vl-column>
            </vl-grid>
          </vl-column>
          <vl-column>
            <vl-grid mod-center>
              <vl-column width="4" width-m="9" width-s="12">
                <vl-select
                  id="organisation"
                  v-model="selectedOrganisationCode"
                  name="organisation"
                  mod-block
                  @update:model-value="selectedOrganisationChanged"
                  :placeholder-text="$t('vl-select.placeholder')"
                >
                  <option v-for="organisation in organisations" :key="organisation.code" :value="organisation.code">
                    {{ organisation.name }}
                  </option>
                </vl-select>
              </vl-column>
            </vl-grid>
          </vl-column>
          <vl-column>
            <vl-grid mod-center>
              <vl-column width="4" width-m="9" width-s="12">
                <vl-button @click="switchOrganisation">{{ $t('organisation.switch.label') }}</vl-button>
              </vl-column>
            </vl-grid>
          </vl-column>
        </vl-grid>
      </vl-layout>
    </vl-region>
  </div>
</template>
<script lang="ts">
import { stores } from '../../store';
import { computed, defineComponent, onMounted, reactive, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const data = reactive({
      selectedOrganisationCode: '',
    });

    const organisationService = stores.OrganisationStore();

    const switchOrganisation = async (): Promise<void> => {
      if (data.selectedOrganisationCode) {
        await organisationService.impersonate(data.selectedOrganisationCode);
        const redirectUri = route.query?.redirectUri as string;
        if (redirectUri !== null && redirectUri !== undefined) {
          router.push(redirectUri);
        } else {
          router.back();
        }
      }
    };

    const selectedOrganisationChanged = (code: string) => {
      data.selectedOrganisationCode = code;
    };

    const organisations = computed(() => organisationService.organisations);

    onMounted(async () => {
      await organisationService.fetchOrganisations();
    });

    return {
      ...toRefs(data),
      switchOrganisation,
      selectedOrganisationChanged,
      organisations,
    };
  },
});
</script>
