<template>
  <vl-properties-list>
    <vl-properties-label>{{ $t('processdelivery.detail.id') }}</vl-properties-label>
    <vl-properties-data>{{ detail.id }}</vl-properties-data>
    <vl-properties-label>{{ $t('processdelivery.detail.label') }}</vl-properties-label>
    <vl-properties-data>{{ detail.label }}</vl-properties-data>
    <vl-properties-label>{{ $t('processdelivery.detail.description') }}</vl-properties-label>
    <vl-properties-data>{{ detail.description }}</vl-properties-data>
  </vl-properties-list>
</template>

<script lang="ts" setup>
import { DeliveryProcessDefinitionDetailDto } from '@/api/portal-api/clients';
import { useProcessDeliveryDetailStore } from '@/modules/configuration/store/process-delivery/process-delivery-detail.store';
import { computed } from 'vue';

const store = useProcessDeliveryDetailStore();



const detail = computed((): DeliveryProcessDefinitionDetailDto => {
  return store.detail;
});

</script>
