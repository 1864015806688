import { createInstance, dateReviver } from '@/api';
import {
  CreateDeliveryProcessDefinitionDto,
  DeliveryProcessDefinitionClient,
  DeliveryProcessDefinitionDetailDto,
  UpdateDeliveryProcessDefinitionDto,
} from '@/api/portal-api/clients';
import { i18n } from '@/i18n';
import { HorizontalPosition, VerticalPosition, useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { useDeliverySpecificationStore } from '../delivery-specification/delivery-specification.store';
import { useProcessDeliveryEditStore } from './process-delivery-edit.store';

export interface ProcessDeliveryDetailStoreState {
  detail?: DeliveryProcessDefinitionDetailDto;
  loading: boolean;
}

export const client = new DeliveryProcessDefinitionClient('', createInstance(), dateReviver);

export const useProcessDeliveryDetailStore = defineStore('process-delivery-detail-store', {
  state: (): ProcessDeliveryDetailStoreState => ({
    detail: null,
    loading: false,
  }),
  getters: {},
  actions: {
    async get(id: number) {
      this.loading = true;
      try {
        this.detail = await client.getDetail(id);
      } catch (error) {
        useAlertStore().setError(
          i18n.global.t('processdelivery.detail.error.get.title'),
          i18n.global.t('processdelivery.detail.error.get.content'),
        );
      } finally {
        this.loading = false;
      }
    },
    async create(dto: CreateDeliveryProcessDefinitionDto): Promise<number> {
      this.loading = true;
      const response = client
        .create(dto)
        .then((response) => {
          return response.id;
        })
        .catch(() => {
          useAlertStore().setError(
            i18n.global.t('processdelivery.create.error.title'),
            i18n.global.t('processdelivery.create.error.content'),
          );
          return null;
        })
        .finally(() => {
          this.loading = false;
          useProcessDeliveryEditStore().closeEdit();
        });

      return response;
    },
    async update(id: number, dto: UpdateDeliveryProcessDefinitionDto) {
      this.loading = true;
      await client
        .update(id, dto)
        .then(() => {
          useAlertStore().setSuccess(
            i18n.global.t('processdelivery.detail.success.update.title'),
            '',
            VerticalPosition.Bottom,
            HorizontalPosition.Right,
          );
          this.get(id);
        })
        .catch(() => {
          useAlertStore().setError(
            i18n.global.t('processdelivery.detail.error.update.title'),
            i18n.global.t('processdelivery.detail.error.update.content'),
          );
        })
        .finally(() => {
          this.loading = false;
          useProcessDeliveryEditStore().closeEdit();
        });
    },
    clear(id: number) {
      this.detail = null;
      useDeliverySpecificationStore().clear(id);
    },
  },
});
