import { createInstance, dateReviver } from '@/api';
import { DeliveryProcessDefinitionClient, OrderDirection, SearchDeliveryProcessDefinitionDto } from '@/api/portal-api/clients';
import { useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { i18n } from '@/i18n';

export interface ProcessDeliveryFilter {
    id?: number | null;
    label?: string;
    description?: string;
    order?: string;
    orderDirection?: OrderDirection;
    pageNumber?: number;
    pageSize?: number;
}

export interface ProcessDeliveryStoreState {
    filter: ProcessDeliveryFilter;
    loading: boolean;
    items?: SearchDeliveryProcessDefinitionDto[];
    totalCount?: number;
}

export const client = new DeliveryProcessDefinitionClient('', createInstance(), dateReviver);

export const useProcessDeliveryStore = defineStore('process-delivery-list-store', {
    state: (): ProcessDeliveryStoreState => ({
        filter: {
            orderDirection: OrderDirection.Ascending,
            pageNumber: 1,
            pageSize: 25,
        },
        totalCount: 0,
        loading: false,
        items: [],
    }),
    getters: {
        metaData(): {
            totalRows: number;
            resultsPerPage: number;
            currentPage: number | undefined;
            serverside: boolean;
        } {
            return {
                totalRows: this.totalCount,
                resultsPerPage: this.filter.pageSize,
                currentPage: this.filter.pageNumber,
                serverside: true,
            };
        },
    },
    actions: {
        init(filter: ProcessDeliveryFilter) {
            this.filter = { ...this.filter, ...filter };
            this.items = [];
        },
        clearFilter() {
            this.filter = {
                orderDirection: OrderDirection.Ascending,
                pageNumber: 1,
                pageSize: 25,
            };

            useAlertStore().clearAlert();

            this.items = [];
        },
        async getModels() {
            this.loading = true;
            useAlertStore().clearAlert();

            await client
                .search(
                    this.filter.id,
                    this.filter.label,
                    this.filter.description,
                    this.filter.order,
                    this.filter.orderDirection,
                    this.filter.pageNumber,
                    this.filter.pageSize,
                )
                .then((response) => {
                    this.items = response.items;
                    this.filter.pageNumber = response.page;
                    this.filter.pageSize = response.pageSize;
                    this.totalCount = response.totalCount;
                })
                .catch((error) => {
                    useAlertStore().setError(
                        i18n.global.t('model.list.error.title'),
                        i18n.global.t('model.list.error.content'),
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        updatePage(pageNumber: number) {
            this.filter.pageNumber = pageNumber;
        },
        updateSorting(column: string, order: string) {
            this.filter.order = column;
            this.filter.orderDirection = order === 'Asc' ? OrderDirection.Ascending : OrderDirection.Descending;
        },
    },
});
