import { defineStore } from 'pinia';

export enum ProcessDeliveryEditType {
    DeliveryEntity,
    DeliveryEntityLink,
    DeliveryEntityLinkAttribute,
    DeliverySpecification,
    DeliveryTransformation,
    DeliveryTransformationAttribute,
    OperationEntity,
    OperationSpecification,
    ProcessDelivery,
}
export interface ProcessDeliveryEditStoreState {
    key?: number;
    type?: ProcessDeliveryEditType;
}

export const useProcessDeliveryEditStore = defineStore('process-delivery-edit-store', {
    state: (): ProcessDeliveryEditStoreState => ({
        key: null,
        type: null,
    }),
    getters: {
        isEdit(): (type: ProcessDeliveryEditType, id: number) => boolean {
            return (type: ProcessDeliveryEditType, id: number) => this.type === type && this.key === id;
        },
        editing(): boolean {
            return this.type !== null && this.key !== null;
        },
    },
    actions: {
        openEdit(type: ProcessDeliveryEditType, id: number) {
            this.type = type;
            this.key = id;
        },
        closeEdit() {
            this.type = null;
            this.key = null;
        },
    },
});
